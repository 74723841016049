import React, { useEffect, useState, useContext } from "react";
import { 
  Box,
  Button,
  Center,
  Flex, 
  Grid,
  GridItem,
  Heading, 
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
  useRadioGroup,
  VStack,
} from "@chakra-ui/react";
import { pdfjs } from 'react-pdf';
import './Research.scss';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ResearchBox = (props) => {
  return (
  <GridItem className='ResearchBox'>
    <Heading m='20px' mb='30px'>{props.heading}</Heading>
    <HStack m='20px'>
      <Image 
        src={props.image} 
        className='ResearchBoxImage'
        objectPosition={props.objectPosition}
      />
      <Text fontSize='22px' textAlign='justify' ml='20px' color='#555555'>{props.text}</Text>
    </HStack>
    <Center>
      <HStack>
        <LinkBox mr='8px'>
          <LinkOverlay href={props.linkGithub} isExternal>
            <Button 
              width='128px' height='49px' 
              border='0.1rem solid rgb(163, 163, 163)'
              borderRadius='49px'
              mr='5px'
              background='#FAFAFA'
              _hover={{background: '#353535', color: 'white'}}
            >
              Github
            </Button>
          </LinkOverlay>
        </LinkBox>
        <LinkBox mr='8px'>
          <LinkOverlay href={props.linkReport} isExternal>
            <Button 
              width='128px' height='49px' 
              border='0.1rem solid rgb(163, 163, 163)'
              borderRadius='49px'
              ml='5px'
              background='#FAFAFA'
              _hover={{background: '#353535', color: 'white'}}
            >
              Report
            </Button>
          </LinkOverlay>
        </LinkBox>
      </HStack>
    </Center>
  </GridItem>
  );
};

export default ResearchBox;
