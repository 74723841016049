import React, { useState, useContext, useEffect,  } from "react";
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Link,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Popover,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { StoreContext } from "../helpers/context";

const Navbar = () => {
  
  const navPadding = "5px";
  const navLinkHoverColor = "gray.400";
  const [bgColor1, setBgColor1] = useState('gray.400');
  const [bgColor2, setBgColor2] = useState('black');
  const [bgColor3, setBgColor3] = useState('black');
  const [bgColor4, setBgColor4] = useState('black');
  const [bgColor5, setBgColor5] = useState('black');

  useEffect(() => {
    const urlList = window.location.href.split('/');
    const url = urlList[urlList.length - 1];
    if (url === '') {
      setBgColor1('gray.400');
      setBgColor2('black');
      setBgColor3('black');
      setBgColor4('black');
      setBgColor5('black');
    } else if (url === 'projects') {
      setBgColor1('black');
      setBgColor2('gray.400');
      setBgColor3('black');
      setBgColor4('black');
      setBgColor5('black');
    } else if (url === 'research') {
      setBgColor1('black');
      setBgColor2('black');
      setBgColor3('gray.400');
      setBgColor4('black');
      setBgColor5('black');
    } else if (url === 'experience') {
      setBgColor1('black');
      setBgColor2('black');
      setBgColor3('black');
      setBgColor4('gray.400');
      setBgColor5('black');
    } else if (url === 'about') {
      setBgColor1('black');
      setBgColor2('black');
      setBgColor3('black');
      setBgColor4('black');
      setBgColor5('gray.400');
    }
  }, []);


  return (
    <>
    <Flex h="100px" color="black">
      <Flex 
        alignItems="end" justifyContent='left' width='50vw'
        pl='15%'
      >
        <Text fontSize='32px'>Mike Gysel</Text>
      </Flex>
      <Flex
        alignItems="end"
        px="1rem"
        textAlign="right"
        justifyContent='right'
        width='100vw'
        pr='10%'
      >
        <Popover trigger={'hover'} placement={'bottom-start'}>
          <PopoverTrigger>
            <Link
              p={2}
              as={RouterLink}
              to={'/'}
              fontSize={'lg'}
              fontWeight={500}
              color={bgColor1}
              _hover={{
                textDecoration: 'none',
                color: navLinkHoverColor,
              }}
              onClick={(e) => {
                setBgColor1('gray.400');
                setBgColor2('black');
                setBgColor3('black');
                setBgColor4('black');
                setBgColor5('black');
              }}
            >
              Home
            </Link>
          </PopoverTrigger>
        </Popover>
        <Popover trigger={'hover'} placement={'bottom-start'}>
          <PopoverTrigger>
            <Link
              p={2}
              as={RouterLink}
              to={'/projects'}
              fontSize={'lg'}
              fontWeight={500}
              color={bgColor2}
              _hover={{
                textDecoration: 'none',
                color: navLinkHoverColor,
              }}
              onClick={(e) => {
                setBgColor1('black');
                setBgColor2('gray.400');
                setBgColor3('black');
                setBgColor4('black');
                setBgColor5('black');
              }}
            >
              Software
            </Link>
          </PopoverTrigger>
        </Popover>
        <Popover trigger={'hover'} placement={'bottom-start'}>
          <PopoverTrigger>
            <Link
              p={2}
              as={RouterLink}
              to={'/research'}
              fontSize={'lg'}
              fontWeight={500}
              color={bgColor3}
              _hover={{
                textDecoration: 'none',
                color: navLinkHoverColor,
              }}
              onClick={(e) => {
                setBgColor1('black');
                setBgColor2('black');
                setBgColor3('gray.400');
                setBgColor4('black');
                setBgColor5('black');
              }}
            >
              Research
            </Link>
          </PopoverTrigger>
        </Popover>
        <Popover trigger={'hover'} placement={'bottom-start'}>
          <PopoverTrigger>
            <Link
              p={2}
              as={RouterLink}
              to={'/experience'}
              fontSize={'lg'}
              fontWeight={500}
              color={bgColor4}
              _hover={{
                textDecoration: 'none',
                color: navLinkHoverColor,
              }}
              onClick={(e) => {
                setBgColor1('black');
                setBgColor2('black');
                setBgColor3('black');
                setBgColor4('gray.400');
                setBgColor5('black');
              }}
            >
              Experience
            </Link>
          </PopoverTrigger>
        </Popover>
        <Popover trigger={'hover'} placement={'bottom-start'}>
          <PopoverTrigger>
            <Link
              p={2}
              as={RouterLink}
              to={'/about'}
              fontSize={'lg'}
              fontWeight={500}
              color={bgColor5}
              _hover={{
                textDecoration: 'none',
                color: navLinkHoverColor,
              }}
              onClick={(e) => {
                setBgColor1('black');
                setBgColor2('black');
                setBgColor3('black');
                setBgColor4('black');
                setBgColor5('gray.400');
              }}
            >
              <Text>About Me</Text>
            </Link>
          </PopoverTrigger>
        </Popover>
      </Flex>
    </Flex>
    </>
  );
};

export default Navbar;
