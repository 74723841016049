import React, { useEffect, useState, useContext } from "react";
import { 
  Box,
  Button,
  Center,
  Flex, 
  Grid,
  GridItem,
  Heading, 
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
  useRadioGroup,
  VStack,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Link as RouterLink } from "react-router-dom";
import { Document, Page, pdfjs } from 'react-pdf';
import { ImLocation2, ImGithub, ImLinkedin } from 'react-icons/im';
import { MdEmail } from 'react-icons/md';
import { FaFileDownload } from 'react-icons/fa';
import { GoLinkExternal } from 'react-icons/go';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const About = () => {

  const highlightColor = '#2C5282';

  return (
  <Flex
    pl='20vw'
    pr='20vw'
    mb='40px'
    mt='40px'
    flexDirection='column'
  >
    <VStack pb='20px'>
      <Text fontSize='16px' fontColor='#555555'>Learn More</Text>
      <Text fontSize='48px'>About Me</Text>
    </VStack>
    <Flex 
      width='60vw' flexDirection='column'
      border='0.1rem solid' borderColor='rgb(163, 163, 163)'
      borderRadius='2rem'
      p='30px' 
      background='#FAFAFA'
      mb='20px'
    >
      <Center>
        <Image 
          src='images/profile_img.png' 
          boxSize='280px' borderRadius='full' 
          objectFit='cover'
        />
      </Center>
      <Center pt='10px'>
        <LinkBox pl='5px' pr='5px'>
          <LinkOverlay href='https://www.linkedin.com/in/mike-gysel' isExternal>
            <Button                   
              width='128px' height='49px' 
              border='0.1rem solid rgb(163, 163, 163)'
              borderRadius='49px'
              mr='5px'
              background='#FAFAFA'
              _hover={{background: '#353535', color: 'white'}}
            >
              LinkedIn
            </Button>
          </LinkOverlay>
        </LinkBox>
        <LinkBox pl='5px' pr='5px'>
          <LinkOverlay href='https://github.com/mgysel' isExternal>
            <Button
              width='128px' height='49px' 
              border='0.1rem solid rgb(163, 163, 163)'
              borderRadius='49px'
              mr='5px'
              background='#FAFAFA'
              _hover={{background: '#353535', color: 'white'}}
            >
              Github
            </Button>
          </LinkOverlay>
        </LinkBox>
        <LinkBox pl='5px' pr='5px'>
          <LinkOverlay href='documents/resume.pdf' isExternal>
            <Button
              width='128px' height='49px' 
              border='0.1rem solid rgb(163, 163, 163)'
              borderRadius='49px'
              mr='5px'
              background='#FAFAFA'
              _hover={{background: '#353535', color: 'white'}}
            >
              Resume
            </Button>
          </LinkOverlay>
        </LinkBox>
        <LinkBox pl='5px' pr='5px'>
          <LinkOverlay href='mailto:mikegysel@gmail.com' isExternal>
            <Button
              width='128px' height='49px' 
              border='0.1rem solid rgb(163, 163, 163)'
              borderRadius='49px'
              mr='5px'
              background='#FAFAFA'
              _hover={{background: '#353535', color: 'white'}}
            >
              Email
            </Button>
          </LinkOverlay>
        </LinkBox>
      </Center>
      <Center>
        <Flex flexDirection='column' pt='20px'>
          <Text pt='10px' textAlign='justify' fontSize='xl'>Hello! I am a recent Master's graduate at EPFL with a major in computer science and minor in management, technology, and entrepreneurship. I have previously worked as a transportation engineer, but switched careers due to my love of computer science and continuous learning! I am currently seeking an entry-level position as a software engineer.</Text>
          <Text pt='10px' textAlign='justify' fontSize='xl'>
            My Master's education at EPFL focused on software engineering, particularly decentralized systems engineering. 
            Under the guidance of EPFL's <a href='https://www.epfl.ch/labs/dedis/' style={{textDecoration: 'underline', color: highlightColor}}>Decentralized and Distributed Computing (DEDIS) Lab</a> and <a href='https://www.epfl-innovationpark.ch/' style={{textDecoration: 'underline', color: highlightColor}}>EPFL Innovation Park</a>, I researched, architected, and developed a <a href='https://github.com/mgysel/blockchain-based-event-ticketing' style={{textDecoration: 'underline', color: highlightColor}}>blockchain-based event ticketing system</a> built on EPFL’s DELA blockchain that improves event organizer control over the secondary ticket market and reduces ticket fraud. Unlike others, this system anonymously attaches event tickets to user identities through the use of secure multiparty computation and multi-signatures which aids in further preventing ticket fraud.
          </Text>
          <Text pt='10px' textAlign='justify' fontSize='xl'>
            I earned my Bachelor's in computer science from UNSW, where my education also focused on software engineering.
            I developed a variety of more traditional software projects, including an <a href='https://github.com/mgysel/MVPVinyls' style={{textDecoration: 'underline', color: highlightColor}}>
              e-commerce vinyl record store
            </a> where users can buy and sell records and receive recommendations based on these purchases; a <a href='https://github.com/mgysel/slacker' style={{textDecoration: 'underline', color: highlightColor}}>simplified version of Slack 
            </a> where users can create channels and message one another; 
            and an <a href='https://github.com/mgysel/nft-ticketing' style={{textDecoration: 'underline', color: highlightColor}}>
              NFT event ticketing system
            </a> built on Ethereum.
          </Text>
        </Flex>
      </Center>
    </Flex>
    <Flex width='20vw'></Flex>
  </Flex>
  );
};

export default About;
