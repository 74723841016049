import Navigation from "./pages/Navigation";
import Navbar from "./components/Navbar";
import StoreProvider from "./helpers/context";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <div className="App" overflow-y='auto'>
      <Router>
        <StoreProvider>
          <Navbar />
          <Navigation />
        </StoreProvider>
      </Router>
    </div>
  );
}

export default App;
