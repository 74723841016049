import React from "react";
import { Routes, Route } from "react-router-dom";
import Landing from "./Landing";
import About from "./about/About";
import Projects from "./projects/Projects";
import Research from "./research/Research";
import Experience from "./experience/Experience";

const Navigation = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Landing />}></Route>
      <Route exact path="/projects" element={<Projects />}></Route>
      <Route exact path="/research" element={<Research />}></Route>
      <Route exact path="/experience" element={<Experience />}></Route>
      <Route exact path="/about" element={<About />}></Route>
    </Routes>
  );
};

export default Navigation;
