import React, { useEffect, useState, useContext } from "react";
import { 
  Box,
  Button,
  Center,
  Flex, 
  Grid,
  GridItem,
  Heading, 
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
  useRadioGroup,
  VStack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Link as RouterLink } from "react-router-dom";
import { Document, Page, pdfjs } from 'react-pdf';
import { ImLocation2, ImGithub, ImLinkedin } from 'react-icons/im';
import { MdEmail } from 'react-icons/md';
import { FaFileDownload, FaPython } from 'react-icons/fa';
import { FaGolang } from 'react-icons/fa6';
import { BiLogoJavascript, BiLogoJava, BiLogoFlask, BiLogoReact, BiLogoMongodb, BiLogoPostgresql, BiLogoAws, BiLogoHeroku } from 'react-icons/bi';
import { SiSolidity } from 'react-icons/si';

import { GoLinkExternal } from 'react-icons/go';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Experience = () => {

  const highlightColor = '#2C5282';
  const iconSize = '50px';
  const techStackM = '3px';

  return (
  <Flex
    pl='12vw'
    pr='12vw'
    mb='40px'
    mt='40px'
    flexDirection='column'
  >
    <VStack pb='20px'>
      <Text fontSize='16px' fontColor='#555555'>Explore My</Text>
      <Text fontSize='48px'>Experience</Text>
    </VStack>
    <Flex flexDirection='row'>
      <Flex        
        border='0.1rem solid' borderColor='rgb(163, 163, 163)'
        borderRadius='2rem'
        p='30px' 
        background='#FAFAFA'
        flexDirection='column'
        width='30vw'
      >
        <Heading textAlign='center' mb='50px'>Education</Heading>
        <Flex flexDirection='row'>
          <Box>
            <Image src='images/epfl-logo.jpeg' width='160px' />
          </Box>
          <Flex flexDirection='column' ml='20px' fontSize='18px'>
            <Text>M.Sc.</Text>
            <Text>Computer Science</Text>
            <Text>(2021 - 2023)</Text>
          </Flex>
        </Flex>
        <Flex flexDirection='row' mt='40px'>
          <Box>
            <Image src='images/unsw-logo.png' width='160px' />
          </Box>
          <Flex flexDirection='column' ml='20px' fontSize='18px'>
            <Text>B.Sc.</Text>
            <Text>Computer Science</Text>
            <Text>(2019 - 2021)</Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex width='4vw'></Flex>
      <Flex 
        border='0.1rem solid' borderColor='rgb(163, 163, 163)'
        borderRadius='2rem'
        p='24px' 
        background='#FAFAFA'
        width='46vw'
        flexDirection='column'
      >
        <Heading mb='30px' textAlign='center'>Tech Stack</Heading>
        <Grid templateColumns='repeat(3, 0.32fr)' gap={8} fontSize='18px' justifyContent='center'>
            <GridItem>
              <HStack>
                <FaPython size={iconSize} />
                <Text ml={techStackM}>Python</Text>
              </HStack>
            </GridItem>
            <GridItem>
              <HStack>
                <FaGolang size={iconSize} />
                <Text ml={techStackM}>Golang</Text>
              </HStack>
            </GridItem>
            <GridItem>
              <HStack>
                <BiLogoJavascript size={iconSize} />
                <Text ml={techStackM}>Javascript</Text>
              </HStack>
            </GridItem>
            <GridItem>
              <HStack>
                <BiLogoJava size={iconSize} />
                <Text ml={techStackM}>Java</Text>
              </HStack>
            </GridItem>
            <GridItem>
              <HStack>
                <SiSolidity size={iconSize} />
                <Text ml={techStackM}>Solidity</Text>
              </HStack>
            </GridItem>
            <GridItem>
              <HStack>
                <BiLogoFlask size={iconSize} />
                <Text ml={techStackM}>Flask</Text>
              </HStack>
            </GridItem>
            <GridItem>
              <HStack>
                <BiLogoReact size={iconSize} />
                <Text ml={techStackM}>ReactJS</Text>
              </HStack>
            </GridItem>
            <GridItem>
              <HStack>
                <BiLogoMongodb size={iconSize} />
                <Text ml={techStackM}>MongoDB</Text>
              </HStack>
            </GridItem>
            <GridItem>
              <HStack>
                <BiLogoPostgresql size={iconSize} />
                <Text ml={techStackM}>PostgreSQL</Text>
              </HStack>
            </GridItem>
            <GridItem>
              <HStack>
                <BiLogoHeroku size={iconSize} />
                <Text ml={techStackM}>Heroku</Text>
              </HStack>
            </GridItem>
            <GridItem>
              <HStack>
                <BiLogoAws size={iconSize} />
                <Text ml={techStackM}>AWS</Text>
              </HStack>
            </GridItem>
        </Grid>
      </Flex>
    </Flex>
  </Flex>
  );
};

export default Experience;
