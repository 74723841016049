import React, { useEffect, useState, useContext } from "react";
import { 
  Box,
  Button,
  Center,
  Flex, 
  Grid,
  GridItem,
  Heading, 
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
  useRadioGroup,
  VStack,
} from "@chakra-ui/react";
import { pdfjs } from 'react-pdf';
import './Research.scss';
import ResearchBox from './ResearchBox';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Research = () => {

  const highlightColor = '#2C5282';

  return (
  <Flex
    pl='12vw'
    pr='12vw'
    mb='40px'
    mt='40px'
    flexDirection='column'
  >
    <VStack pb='20px'>
      <Text fontSize='16px' fontColor='#555555'>Browse My Recent</Text>
      <Text fontSize='48px'>Research Projects</Text>
    </VStack>
    <Grid width='76vw' templateColumns='repeat(1, 2fr)' gap={10}>
      <ResearchBox 
        heading='Blockchain-based Event Ticketing'
        image='images/nft-ticket.jpeg'
        text='Blockchain-based event ticketing system that improves event organizer control over the secondary ticket market and reduces ticket fraud. 
        Unlike others, this system anonymously attaches event tickets to user identities through the use of secure multi-party computation and multi-signatures which aids in further preventing ticket fraud.'
        linkGithub='https://github.com/mgysel/blockchain-based-event-ticketing'
        linkReport='documents/blockchain-based-event-ticketing.pdf'
        objectPosition=''
      />
      <ResearchBox 
        heading='Sealed-bid Blockchain Auction'
        image='images/sealed-bid2.png'
        text="Design, Implementation, and Evaluation of a sealed-bid blockchain auction on EPFL's DELA blockchain. Sealed-bid auctions present challenges on blockchains as bids must be kept secret even though blockchains are transparent. 
        The use of a decentralized secret-management committee was used to address this, which allows bids to be encrypted during the bidding period, and decrypted and executed at the end of the auction."
        linkGithub='https://github.com/mgysel/blockchain-auction-f3b'
        linkReport='documents/sealed-bid-blockchain-auction.pdf'
        objectPosition='25% 20%'
      />
      <ResearchBox 
        heading='Predicting Bike-Share Demand'
        image='images/bike-demand3.jpeg'
        text="Predicting station-level hourly bike-sharing demand in New York City using XGBoost and Long Short-Term Memory (LSTM). 
        The study used two datasets: New York City Citi Bike bike-sharing demand data and National Oceanic and Atmospheric Administration meteorological data.
        XGBoost outperformed LSTM with an R2 of 0.75 and RMSE of 1.31."
        linkGithub='https://github.com/mgysel/Bike-Demand-Models'
        linkReport='documents/bike-demand-models.pdf'
        objectPosition='10% 20%'
      />
    </Grid>
  </Flex>
  );
};

export default Research;
