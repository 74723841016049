import React, { useEffect, useState, useContext } from "react";
import { 
  Box,
  Button,
  Center,
  Flex, 
  Grid,
  GridItem,
  Heading, 
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
  useRadioGroup,
  VStack,
} from "@chakra-ui/react";
import { pdfjs } from 'react-pdf';
import './Projects.scss';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ProjectBox = (props) => {

  return (
    <GridItem 
      className='ProjectBox'
    >
      <Center>
        <Image 
          src={props.image} 
          className='ProjectBoxImage'
        />
      </Center>
      <Center>
        <Heading pt='10px' m='20px' fontSize='31px'>{props.heading}</Heading>
      </Center>
      <Center>
        <HStack>
          <LinkBox mr='8px'>
            <LinkOverlay href={props.linkGithub} isExternal>
              <Button 
                width='114px' height='49px' 
                border='0.1rem solid rgb(163, 163, 163)'
                borderRadius='49px'
                mr='5px'
                background='#FAFAFA'
                _hover={{background: '#353535', color: 'white'}}
              >
                Github
              </Button>
            </LinkOverlay>
          </LinkBox>
          <LinkBox ml='8px'>
            <LinkOverlay href={props.linkDemo} isExternal>
              <Button 
                width='114px' height='49px' 
                border='0.1rem solid rgb(163, 163, 163)'
                borderRadius='49px'
                mr='5px'
                background='#FAFAFA'
                _hover={{background: '#353535', color: 'white'}}
              >
                Live Demo
              </Button>
            </LinkOverlay>
          </LinkBox>
        </HStack>
      </Center>
    </GridItem>
  );
};

export default ProjectBox;
