import React, { useEffect, useState, useContext } from "react";
import { 
  Box,
  Button,
  Center,
  Flex, 
  Grid,
  GridItem,
  Heading, 
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
  useRadioGroup,
  VStack,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Link as RouterLink } from "react-router-dom";
import { Document, Page, pdfjs } from 'react-pdf';
import { ImLocation2, ImGithub, ImLinkedin } from 'react-icons/im';
import { MdEmail } from 'react-icons/md';
import { FaFileDownload } from 'react-icons/fa';
import { GoLinkExternal } from 'react-icons/go';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Landing = () => {

  const iconSize = '20px';
  const bioVertMargin = '15px';

  return (
    <Flex
      p="1rem"
      flexDirection="column"
      verticalAlign="top"
      pt = '70px'
    >
      <HStack>
        <Flex 
          width='50vw'
          justifyContent='right'
          pr='50px'
        >
          <Image 
            src='images/profile_img.png' 
            boxSize='400px' borderRadius='full' 
            mt={bioVertMargin}
            objectFit='cover'
          />
        </Flex>
        <Flex width='50vw'>
          <VStack 
            width='30vw'
            justifyContent='left'
          >
            <Text fontSize='16px' color='#555555' fontWeight='600'>Hello, I'm</Text>
            <Text fontSize='48px'>Mike Gysel</Text>
            <Text fontSize='28px' mb='16px' color='#555555'>Software Engineer</Text>
            <HStack>
              <LinkBox pb='3px' _hover={{textDecoration: "underline"}}>
                <LinkOverlay href='documents/resume.pdf' isExternal>
                  <Button 
                    width='180px' height='55px' 
                    border='1px solid black' borderRadius='49px' 
                    fontSize='16px' 
                    background='#353535' color='white'
                    mr='5px'
                    _hover={{background: 'gray.100', color: '#353535'}}
                  >
                    Download Resume
                  </Button>
                </LinkOverlay>
              </LinkBox>
              <LinkBox pb='3px' _hover={{textDecoration: "underline"}}>
                <LinkOverlay href='mailto:mikegysel@gmail.com' isExternal>
                  <Button 
                    width='180px' height='55px' 
                    border='1px solid black' borderRadius='49px' 
                    fontSize='16px' 
                    background='#353535' color='white'
                    ml='5px'
                    _hover={{background: 'gray.100', color: '#353535'}}
                  >
                    Email
                  </Button>
                </LinkOverlay>
              </LinkBox>
            </HStack>
            <HStack pt='16px'>
              <LinkBox pb='3px' _hover={{textDecoration: "underline"}} mr='5px'>
                <LinkOverlay href='https://www.linkedin.com/in/mike-gysel' isExternal>
                  <ImLinkedin size='40px' />
                </LinkOverlay>
              </LinkBox>
              <LinkBox pb='3px' _hover={{textDecoration: "underline"}} ml='5px'>
                <LinkOverlay href='https://github.com/mgysel' isExternal>
                  <ImGithub size='40px' />
                </LinkOverlay>
              </LinkBox>
            </HStack>
          </VStack>
        </Flex>

      </HStack>
  </Flex>
  );
};

export default Landing;
