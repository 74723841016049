import React, { useEffect, useState, useContext } from "react";
import { 
  Box,
  Button,
  Center,
  Flex, 
  Grid,
  GridItem,
  Heading, 
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
  useRadioGroup,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { pdfjs } from 'react-pdf';
import './Projects.scss';
import ProjectBox from './ProjectBox';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Projects = () => {

  return (
  <Flex
    pl='7%'
    pr='7%'
    mb='40px'
    mt='40px'
    flexDirection='column'
  >
    <VStack pb='20px'>
      <Text fontSize='16px' fontColor='#555555'>Browse My Recent</Text>
      <Text fontSize='48px'>Software Projects</Text>
    </VStack>
    <Grid templateColumns='repeat(3, 2fr)' gap={10}>
      <ProjectBox 
        heading='Record Shop'
        image='images/record-shop2.jpeg'
        linkGithub='https://github.com/mgysel/MVPVinyls'
        linkDemo='https://mvpvinyls-4b651d444282.herokuapp.com/'

      />
      <ProjectBox 
        heading='NFT Ticketing'
        image='images/nft-ticket2.jpeg'
        linkGithub='https://github.com/mgysel/nft-ticketing'
        linkDemo='https://nft-event-ticketing-0c6fb6610440.herokuapp.com/'
      />
      <ProjectBox 
        heading='Slackr'
        image='images/slackr-4.webp'
        linkGithub='https://github.com/mgysel/slacker'
        linkDemo='https://slackr-4fa3d8731f8d.herokuapp.com/login'
      />
    </Grid>
  </Flex>
  );
};

export default Projects;
